<template>
  <div>
    <select-shop :loadingButton="loadingButton"></select-shop>
    <form class="mt-4">
      <CCol  md="12" lg="12">
        <CCard class="shadow-sm">
          <CCardBody style="overflow-y: auto;">
            <div class="row">
              <div class="col-md-9 col-sm-8 col-8 d-inline-flex align-items-center">
                <h2 class="font-weight-normal text-dark">
                  {{ $t("totalOfImportProduct") }}
                  <span>({{ tableData.length }} {{ $t("item") }}) </span>
                </h2>
                <HelpButton :isHeader="false" class="ml-2 mb-2"></HelpButton>
              </div>
              <!-- <div class="col-md-3 col-sm-4 col-4 text-right text-dark">
                <CButton
                  block
                  class="btn btn-outline-success btn-block"
                  @click="openAddFileModal()"
                >
                  {{ $t("uploadFile") }}
                </CButton>
              </div> -->
              <div class="col-md-3 col-sm-4 col-4 text-right text-dark">
                <CButton id="importProductDownloadButton" block class="btn btn-outline-success btn-block" @click="goToGoogleSheet()">
                  {{ $t("downloadFileExample") }}
                </CButton>
              </div>
            </div>
            <CRow>
              <p class="ml-2 mt-2">{{ $t("moreInfo") }}</p>
              <i @click="openDownloadModal()" class="fi fi-rr-info ml-2 mt-2" style="font-size: large"></i>
            </CRow>
            <hr />
            <div v-if="tableData.length > 0" style="overflow-x: auto">
              <ag-grid-vue id="importProductTable" class="ag-theme-balham text-dark" style="width: 100%; height: 600px" :columnDefs="columnDefs"
                :rowData="tableData" :rowClassRules="rowClassRules" @grid-ready="onGridReady"></ag-grid-vue>
            </div>
            
            <div v-else>
              <label
                class="row w-100"
                style="display: flex; flex-direction: column; justify-content: center; align-items: center;"
              >
                <CRow class="w-100 mb-3">
                  <CCol lg="6" md="6" sm="6">
                    <h4 class="text-center" v-html="$t('importdesc')"></h4>
                  </CCol>
                </CRow>
                <CRow class="w-100 d-flex align-items-center">
                  <CCol
                    md="6"
                    sm="12"
                    class="d-flex justify-content-center"
                    style="
                      border-radius: 10px;
                      border-style: dashed;
                      border-color: #cfcfcf;
                      border-width: 5px;
                      height: auto;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      flex-direction: column;
                      padding:10%
                    "
                  >
                    <input id="importProductFileInput" style="visibility: hidden" type="file" @change="handleFileUpload" ref="fileInput" />
                    <div v-if="!file" class="text-center" >
                      <i class="fi fi-rr-upload" style="font-size: xx-large"></i>
                      <h3 class="mt-2">{{ $t("uploadFile") }}</h3>
                    </div>
                    <div id="importProductFileInfo" v-else class="file-info text-center">
                      <p>{{ file.name }}</p>
                    </div>
                  </CCol>
                  <CCol
                    md="6"
                    sm="12"
                    class="d-flex justify-content-center align-items-center"
                    style="height: 100%;"
                  >
                    <img
                      id="importProductTutorialImage"
                      src="\img\icon\tutorial.gif"
                      alt="Tutorial Icon"
                      class="img-fluid"
                      style="object-fit: contain; max-height: 100%;"
                    />
                  </CCol>
                </CRow>
                <CRow class="w-100 mt-3">
                  <CCol md="12" sm="12">
                    <h6 class="text-center mt-3" v-html="$t('importdesc2')"></h6>
                  </CCol>
                </CRow>
              </label>
            </div>
            <CRow class="justify-content-end mt-3">
              <CCol v-if="file && tableData.length === 0" class="col-3">
                <CButton id="importProductClearButton" block class="btn btn-outline-success btn-block" @click="clearFile" :disabled="!addFileCorrect">
                  {{ $t("clear") }}
                </CButton>
              </CCol>
              <CCol v-if="file && tableData.length === 0" class="col-3">
                <CButton id="importProductUploadFileButton" block color="success" @click="readFileContent(file)" :disabled="!addFileCorrect">
                  {{ $t("uploadFile") }}
                </CButton>
              </CCol>
              <CCol v-if="tableData.length > 0" class="col-3">
                <CButton id="importProductConfirmClearButton" block variant="outline" color="success" @click="confirmClearModal()">
                  {{ $t("clear") }}
                </CButton>
              </CCol>
              <CCol v-if="tableData.length > 0 && !saveButton" class="col-3">
                <CButton id="importProductVerifyButton" block color="success" @click="checkFile" :disabled="isVerifying">
                  <CSpinner v-if="isVerifying" color="white" size="sm" />
                  {{ isVerifying ? $t("verify") : $t("verify") }}
                </CButton>
              </CCol>
              <CCol v-if="tableData.length > 0 && saveButton" class="col-3">
                <CButton id="importProductSaveButton"block color="success" @click="confirmSaveModal" :disabled="isSaving">
                  <CSpinner v-if="isSaving" color="white" size="sm" />
                  {{ isSaving ? $t("save") : $t("save") }}
                </CButton>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>

      <CModal id="importProductConfirmModal" color="success" :show.sync="confirmModal" :title="$t('confirmSaveImg')" :style="{ 'max-width': '800px' }"
        centered>
        <br />
        <h4 class="text-center">{{ $t("pass") }} {{ totalPass }} {{ $t("item") }}</h4>
        <h4 class="text-center text-danger">
          {{ $t("fail") }} {{ totalFalse }} {{ $t("item") }}
        </h4>
        <h5 class="text-center text-danger">{{ $t("alertImportProductDialog") }}</h5>
        <h5 class="text-center text-danger">{{ $t("confirmSaveImgDialog") }}</h5>
        <template #footer>
          <CRow class="justify-content-center col-md-12">
            <CCol col="5" lg="4">
              <CButton id="importProductSaveButton" block v-if="showbuttonsave && !savingInProgress" color="success" @click="saveFile">
                {{ $t("save") }}
              </CButton>
              <CButton id="importProductSaveDisabledButton"  v-else block color="success" disabled>
                <CSpinner color="white" size="sm" /> {{ $t("save") }}
              </CButton>
            </CCol>
            <CCol col="5" lg="4">
              <CButton id="importProductCancelButton" block color="light" @click="confirmModal = false">
                {{ $t("cancel") }}
              </CButton>
            </CCol>
          </CRow>
        </template>
      </CModal>
      <CRow>
        <CModal id="importProductClearModal"  color="success" :show.sync="clearModal" :title="$t('clearTableData')" centered>
          <h4 class="text-center">{{ $t("clearTableDataDialog") }}</h4>
          <br />
          <h5 class="text-center">{{ $t("clearTableData") }}</h5>
          <template #footer>
            <CRow class="justify-content-center col-md-12">
              <CCol col="5" lg="4">
                <CButton id="importProductClearConfirmButton"  block v-if="clearButton" color="success" @click="clearTable()">
                  {{ $t("clear") }}
                </CButton>
                <CButton id="importProductClearDisabledButton"  v-else block color="success" disabled>
                  <CSpinner color="white" size="sm" /> {{ $t("clear") }}
                </CButton>
              </CCol>
              <CCol col="5" lg="4">
                <CButton id="importProductClearCancelButton"  block color="light" @click="clearModal = false">
                  {{ $t("cancel") }}
                </CButton>
              </CCol>
            </CRow>
          </template>
        </CModal>
      </CRow>
      <CRow>
        <CModal id="importProductSuccessModal"  color="success" :show.sync="successModal" :title="$t('saveSuccess')" centered>
          <h5 class="text-center mt-3">{{ $t("saveSuccessDialog") }}</h5>
          <h5 class="text-center">{{ $t("saveSuccessDialog2") }}</h5>
          <template #footer>
            <CRow class="justify-content-center col-md-12">
              <CCol col="5">
                <CButton id="importProductDoneButton"  block v-if="successButton" color="success" @click="goback()">
                  {{ $t("done") }}
                </CButton>
                <CButton id="importProductDoneDisabledButton"  v-else block color="success" disabled>
                  <CSpinner color="white" size="sm" /> {{ $t("done") }}
                </CButton>
              </CCol>
            </CRow>
          </template>
        </CModal>
      </CRow>
      <CRow v-if="this.helpModal === true">
        <CModal id="importProductDownloadModal" :show.sync="downloadModal">
          <h2 class="text-center mb-3">{{ $t("requirementFileCreationWarning") }}</h2>
          <h6 class="text-center">{{ $t("pleaseMakeCopyToDownload") }}</h6>
          <div class="text-left pl-4">
            <p>
            <p>1. {{ $t("requirementDownloadCSV") }}</p>
            <p>
              2.. {{ $t('requirementFillMandatoryFields') }}
              <span class="text-danger">*</span>
              {{ $t('must') }}
              {{ $t('everyFields') }}
            </p>
            <p>3. {{ $t('requirementSameProductDifferentSizeColor') }}</p>
            <p>
              4.
              {{ $t('requirementUniqueBarcode') }}
              <span class="text-danger">*</span>
              {{ $t('requirenotduplicate') }}
            </p>

            </p>
            <p>5. {{ $t("requirementVAT") }}</p>
            <p class="pl-4">- {{ $t("requirementVATWithTax") }}</p>
            <p class="pl-4">- {{ $t("requirementVATWithoutTax") }}</p>
            <p>6. {{ $t("requirementProductType") }}</p>
            <p class="pl-4">- {{ $t("requirementProductTypeGeneral") }}</p>
            <p class="pl-4">- {{ $t("requirementProductTypeBOM") }}</p>
            <p class="pl-4">- {{ $t("requirementProductTypeSN") }}</p>
            <p class="pl-4">- {{ $t("requirementProductTypeSV") }}</p>
            <p>7. {{ $t("requirementNumericValues") }}</p>
            <p>8. {{ $t('requirementSync') }}</p>
            
          </div>
          <div class="pl-4 pr-4"><span class="text-danger pl-4">* </span>{{ $t('transferOutNoteTitle') }} {{
      $t('importRemark')
    }}
          </div>

          <template #footer>
            <div class="mx-auto text-center">
              <p>
                <input id="importProductDoNotShowCheckbox" type="checkbox" @click="handlePopup" />
                {{ $t("donotShow") }}
              </p>
            </div>
          </template>
        </CModal>
      </CRow>
      <CModal id="importProductInProgressModal" color="warning" :show.sync="importInProgressModal" :title="$t('importWarning')" centered>
        <div class="text-center mt-3">
          <h5>{{ $t("importWarningDialog") }}</h5>
          <h5>{{ $t("importPleaseWait") }}</h5>
        </div>
        <template #footer>
          <div style="display: flex; justify-content: center; width: 100%">
            <CButton id="importProductSubmitButton"  style="width: 30%; color: white" color="warning" @click="clearTable()">
              {{ $t("submit") }}
            </CButton>
          </div>
        </template>
      </CModal>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import permis from "@/util/permission";
import axios from "@/services/local";
import { AgGridVue } from "ag-grid-vue";
import { CModal, CSpinner } from "@coreui/vue"
import HelpButton from '../../containers/HelpButton.vue'
export default {
  data() {
    return {
      gridApi: null,
      totalPass: 0,
      totalFalse: 0,
      isVerifying: false,
      isSaving: false,
      helpModal: null,
      validateShop: false,
      shop: this.$store.getters.shopObjectId,
      downloadModal: true,
      downloadButton: false,
      addFileModal: false,
      confirmModal: false,
      loadingSaveimg: true,
      showbuttonsave: false,
      loadingButton: true,
      deleteModal: false,
      loading: false,
      titlename: "",
      file: null,
      tableData: [],
      addFileCorrect: false,
      headerMap: [],
      rowsMap: [],
      mappedHeaders: {},
      saveButton: false,
      clearModal: false,
      clearButton: false,
      successButton: false,
      validatedData: [],
      successModal: false,
      savingInProgress: false,
      importInProgressModal: false,
      columnDefs: [
        {
          headerName: "#",
          field: "rowIndex",
          valueGetter: "node.rowIndex + 1",
          sortable: false,
          width: 50,
          cellClassRules: {
            "pass-row": "data.pass === true",
            "red-row": "data.pass === false",
          },
        },
        {
          headerName: this.$t("SKUCode"),
          field: "sku",
          cellClassRules: {
            "pass-row": "data.pass === true",
            "red-row": "data.pass === false",
          },
        },
        {
          headerName: this.$t("barcode"),
          field: "barcode",
          cellClassRules: {
            "pass-row": "data.pass === true",
            "red-row": "data.pass === false",
          },
        },
        {
          headerName: this.$t("category"),
          field: "category",
          cellClassRules: {
            "pass-row": "data.pass === true",
            "red-row": "data.pass === false",
          },
        },
        {
          headerName: this.$t("productName"),
          field: "name",
          cellClassRules: {
            "pass-row": "data.pass === true",
            "red-row": "data.pass === false",
          },
        },
        {
          headerName: this.$t("price"),
          field: "price",
          width: 90,
          cellClassRules: {
            "pass-row": "data.pass === true",
            "red-row": "data.pass === false",
          },
        },
        {
          headerName: this.$t("cost"),
          field: "cost",
          width: 90,
          cellClassRules: {
            "pass-row": "data.pass === true",
            "red-row": "data.pass === false",
          },
        },
        {
          headerName: this.$t("unit"),
          field: "unit",
          width: 90,
          cellClassRules: {
            "pass-row": "data.pass === true",
            "red-row": "data.pass === false",
          },
        },
        {
          headerName: this.$t("SKURatio"),
          field: "ratio",
          width: 80,
          cellClassRules: {
            "pass-row": "data.pass === true",
            "red-row": "data.pass === false",
          },
        },
        {
          headerName: "VAT",
          field: "vat",
          width: 50,
          cellClassRules: {
            "pass-row": "data.pass === true",
            "red-row": "data.pass === false",
          },
        },
        {
          headerName: this.$t("productType"),
          field: "type",
          width: 100,
          cellClassRules: {
            "pass-row": "data.pass === true",
            "red-row": "data.pass === false",
          },
        },
        {
          headerName: this.$t("productSN"),
          field: "serial",
          cellClassRules: {
            "pass-row": "data.pass === true",
            "red-row": "data.pass === false",
          },
        },
        {
          headerName: this.$t("reason"),
          field: "message",
          width: 250,
          cellClassRules: {
            "green-cell": "data.pass === true",
            "red-row": "data.pass === false",
          },
        },
      ],
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
      },
      rowClassRules: {
        "red-row": (params) => params.data.pass === false,
        "green-row": (params) => params.data.pass === true,
      },
    };
  },
  components: {
    AgGridVue,
    HelpButton
  },
  computed: {
    ...mapGetters(["shops", "users", "date"]),
    isPermission() {
      let path = "/product";
      return permis.findPermissionRead("product", path);
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId;
      },
      set(newValue) {
        return this.$store.dispatch("setShop", newValue);
      },
    },
    uid() {
      let shop = this.shops.find((i) => i.objectId === this.shopObjectId);
      return shop.uid;
    },
  },
  created() {
    this.shop = this.shops.find((i) => i.objectId === this.shop);
    this.helpModal = localStorage.getItem("helpModal") !== "false";
  },
  mounted() {
    document.addEventListener("keydown", this.doSave);
  },
  methods: {
    handlePopup() {
      this.helpModal = false;
      this.downloadModal = false;
      localStorage.setItem("helpModal", false);
    },
    openDownloadModal() {
      this.titlename = this.$i18n.t("downloadFileExample");
      this.downloadModal = true;
      this.downloadButton = true;
    },
    goToGoogleSheet() {
      window.open(
        "https://docs.google.com/spreadsheets/d/1j7TlBYDwLjZheAF9r5t30IS49VyUEPXIhEuQ08buLcE/copy?usp=sharing",
        "_blank"
      );
    },
    clearTable() {
      this.tableData = [];
      this.file = null;
      this.clearButton = false;
      this.saveButton = false;
      this.clearModal = false;
      this.importInProgressModal = false;
      localStorage.removeItem("headerMap");
      localStorage.removeItem("rows");
      localStorage.removeItem("mappedHeaders");
    },
    confirmClearModal() {
      this.clearModal = true;
      this.clearButton = true;
    },
    confirmSaveModal() {
      this.saveButton = true;
      this.confirmModal = true;
    },
    openAddFileModal() {
      this.titlename = this.$i18n.t("นำเข้าไฟล์");
      this.addFileModal = true;
    },
    handleFileUpload(event) {
      this.loadingButton = true;
      const file = event.target.files[0];
      if (file && file.type === "text/csv") {
        this.addFileCorrect = true;
        this.file = file;
      } else {
        alert("Please upload a CSV file.");
      }
    },
    clearFile() {
      this.file = null;
      this.$refs.fileInput.value = null;
      this.addFileModal = true;
      localStorage.removeItem("headerMap");
      localStorage.removeItem("rows");
      localStorage.removeItem("mappedHeaders");
      localStorage.removeItem("header");
    },
    readFileContent(file) {
      const reader = new FileReader();
      this.saveButton = false;
      reader.onload = (e) => {
        const csvData = e.target.result;
        const decodedCsvData = new TextDecoder("UTF-8").decode(
          new Uint8Array(e.target.result)
        );
        const rows = decodedCsvData.split("\n");
        const mappedHeaders = {
          sku: 0,
          barcode: 1,
          category: 2,
          name: 3,
          price: 4,
          cost: 5,
          unit: 6,
          ratio: 7,
          vat: 8,
          type: 9,
          serial: 10,
          created_at: -1,
          updated_at: -1,
        };
        let header = [];
        let tableData = [];

        rows.forEach((row, index) => {
          const columns = row.split(",");
          if (columns.length === 1) return;

          if (index === 0) {
            header = columns.map((header) => (header ? header.trim() : ""));
          } else {
            const rowData = { row: index };
            for (const key of Object.keys(mappedHeaders)) {
              const columnIndex = mappedHeaders[key];
              if (columnIndex !== -1) {
                rowData[key] = columns[columnIndex] ? columns[columnIndex].trim() : "";
              }
            }
            tableData.push(rowData);
          }
        });

        this.tableData = tableData;

        this.headerMap = header;
        this.rowsMap = tableData;
        this.mappedHeaders = mappedHeaders;

        localStorage.setItem("rows", JSON.stringify(tableData));
        localStorage.setItem("headerMap", JSON.stringify(header));
        localStorage.setItem("mappedHeaders", JSON.stringify(mappedHeaders));
        this.addFileModal = false;
      };
      reader.readAsArrayBuffer(file);
    },
    goback() {
      this.$router.push("/product");
      localStorage.removeItem("headerMap");
      localStorage.removeItem("rows");
      localStorage.removeItem("mappedHeaders");
      localStorage.removeItem("header");
    },
    checkFile() {
      this.saveButton = false;
      this.showbuttonsave = false;
      let uid = this.uid;
      this.isVerifying = true;
      let url = `/api/v1.0/${uid}/product/import/validate`;

      let header = JSON.parse(localStorage.getItem("headerMap"));
      let rows = JSON.parse(localStorage.getItem("rows"));
      let mappedHeaders = JSON.parse(localStorage.getItem("mappedHeaders"));
      rows = rows.map((row, index) => {
        return {
          ...row,
          row: index + 1,
        };
      });
      const data = {
        shopObjectId: this.shopObjectId,
        rows: rows,
        mappedHeaders: mappedHeaders,
        header: header,
      };

      const headers = {
        "Content-Type": "application/json",
        shopObjectId: this.shopObjectId,
      };

      axios({
        method: "post",
        url: url,
        data: data,
        headers: headers,
      })
        .then((response) => {
          if (response.data.error && response.data.error.code === 4000) {
            this.importInProgressModal = true;
            this.isVerifying = false;
            return;
          }
          let validatedRows = response.data.data;
          let passCount = 0;
          let failCount = 0;

          validatedRows.forEach((validatedRow) => {
            let existingRow = this.tableData.find((row) => row.row === validatedRow.row);

            if (validatedRow.pass === false) {
              failCount++;
              switch (validatedRow.message) {
                case "Attribute sku is required.":
                  validatedRow.message = this.$t("skuRequired");
                  break;
                case "Attribute barcode is required.":
                  validatedRow.message = this.$t("barcodeRequired");
                  break;
                case "Attribute category is required.":
                  validatedRow.message = this.$t("categoryRequired");
                  break;
                case "Attribute name is required.":
                  validatedRow.message = this.$t("nameRequired");
                  break;
                case "Attribute price is required.":
                  validatedRow.message = this.$t("priceRequired");
                  break;
                case "Attribute price must be a number.":
                  validatedRow.message = this.$t("priceMustBeNumber");
                  break;
                case "Attribute cost must be a number.":
                  validatedRow.message = this.$t("costRequired");
                  break;
                case "Attribute ratio must be a number.":
                  validatedRow.message = this.$t("ratioRequired");
                  break;
                case "Barcode duplicated":
                  validatedRow.message = this.$t("barcodedup");
                  break;
                case "Attribute cost should not contain alphabet.":
                  validatedRow.message = this.$t("costNotAlphabet");
                  break;
                case "Attribute price should not contain alphabet.":
                  validatedRow.message = this.$t("priceNotAlphabet");
                  break;
                case "Attribute sku is invalid.":
                  validatedRow.message = this.$t("skuInvalid");
                  break;
                case "Attribute barcode is invalid.":
                  validatedRow.message = this.$t("barcodeInvalid");
                  break;
                case "Attribute category is invalid.":
                  validatedRow.message = this.$t("categoryInvalid");
                  break;
                case "Attribute name is invalid.":
                  validatedRow.message = this.$t("nameInvalid");
                  break;
                case "Attribute price is invalid.":
                  validatedRow.message = this.$t("priceInvalid");
                  break;
                case "Barcode already exists":
                  validatedRow.message = this.$t("barcodeExists");
                  break;
                case "Barcode already exists.":
                  validatedRow.message = this.$t("barcodeExists");
                  break;
                default:
                  validatedRow.message = this.$t("serialNumberisDup");
                  break;
              }

              if (existingRow) {
                existingRow.message = validatedRow.message;
                existingRow.pass = false;
              }
            } else {
              passCount++;
              validatedRow.message = this.$t("pass");

              if (existingRow) {
                existingRow.message = validatedRow.message;
                existingRow.pass = true;
              }
            }
          });

          this.$nextTick(() => {
            this.gridApi.refreshCells({
              force: true,
            });
          });

          this.saveButton = true;
          this.showbuttonsave = true;
          this.validatedData = validatedRows.filter((row) => row.pass === true);
          this.totalPass = passCount;
          this.totalFalse = failCount;
          this.isVerifying = false;
        })
        .catch((error) => {
          console.error(error);
          this.isVerifying = false;
        });
    },
    saveFile() {
      this.successModal = false;
      let uid = this.uid;
      this.isSaving = true;
      this.savingInProgress = true;
      const data = {
        shopObjectId: this.shopObjectId,
        rows: this.validatedData,
      };

      const headers = {
        "Content-Type": "application/json",
      };

      axios({
        method: "post",
        url: "/api/v1.0/" + uid + "/product/import/save",
        data: data,
        headers: headers,
      })
        .then((response) => {
          if (response.data.error && response.data.error.code === 4000) {
            this.importInProgressModal = true;
            this.isSaving = false;
            this.savingInProgress = false;
            this.successModal = false;
            this.confirmModal = false;
            return;
          }
          this.file = null;
          this.tableData = [];
          this.confirmModal = false;
          this.successModal = true;
          this.successButton = true;
          localStorage.removeItem("headerMap");
          localStorage.removeItem("rows");
          localStorage.removeItem("mappedHeaders");
          localStorage.removeItem("header");
          this.isSaving = false;
          this.savingInProgress = false;
        })
        .catch((error) => {
          console.error(error);
          this.isSaving = false;
          this.savingInProgress = false;
        });
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.refreshTable();
    },
    refreshTable() {
      if (this.gridApi) {
      this.columnDefs = [
        {
          headerName: "#",
          field: "rowIndex",
          valueGetter: "node.rowIndex + 1",
          sortable: false,
          width: 50,
          cellClassRules: {
            "pass-row": "data.pass === true",
            "red-row": "data.pass === false",
          },
        },
        {
          headerName: this.$t("SKUCode"),
          field: "sku",
          cellClassRules: {
            "pass-row": "data.pass === true",
            "red-row": "data.pass === false",
          },
        },
        {
          headerName: this.$t("barcode"),
          field: "barcode",
          cellClassRules: {
            "pass-row": "data.pass === true",
            "red-row": "data.pass === false",
          },
        },
        {
          headerName: this.$t("category"),
          field: "category",
          cellClassRules: {
            "pass-row": "data.pass === true",
            "red-row": "data.pass === false",
          },
        },
        {
          headerName: this.$t("productName"),
          field: "name",
          cellClassRules: {
            "pass-row": "data.pass === true",
            "red-row": "data.pass === false",
          },
        },
        {
          headerName: this.$t("price"),
          field: "price",
          width: 90,
          cellClassRules: {
            "pass-row": "data.pass === true",
            "red-row": "data.pass === false",
          },
        },
        {
          headerName: this.$t("cost"),
          field: "cost",
          width: 90,
          cellClassRules: {
            "pass-row": "data.pass === true",
            "red-row": "data.pass === false",
          },
        },
        {
          headerName: this.$t("unit"),
          field: "unit",
          width: 90,
          cellClassRules: {
            "pass-row": "data.pass === true",
            "red-row": "data.pass === false",
          },
        },
        {
          headerName: this.$t("SKURatio"),
          field: "ratio",
          width: 80,
          cellClassRules: {
            "pass-row": "data.pass === true",
            "red-row": "data.pass === false",
          },
        },
        {
          headerName: "VAT",
          field: "vat",
          width: 50,
          cellClassRules: {
            "pass-row": "data.pass === true",
            "red-row": "data.pass === false",
          },
        },
        {
          headerName: this.$t("productType"),
          field: "type",
          width: 100,
          cellClassRules: {
            "pass-row": "data.pass === true",
            "red-row": "data.pass === false",
          },
        },
        {
          headerName: this.$t("productSN"),
          field: "serial",
          cellClassRules: {
            "pass-row": "data.pass === true",
            "red-row": "data.pass === false",
          },
        },
        {
          headerName: this.$t("reason"),
          field: "message",
          width: 250,
          cellClassRules: {
            "green-cell": "data.pass === true",
            "red-row": "data.pass === false",
          },
        },
      ];
      }
      this.gridApi.setColumnDefs(this.columnDefs);
      this.gridApi.refreshCells({ force: true });
    },
    updateMessages() {
      this.tableData.forEach((row) => {
        if (row.pass === true) {
          row.message = this.$t("pass");
        } else {
          switch (row.message) {
            case "Attribute sku is required.":
              row.message = this.$t("skuRequired");
              break;
            case "Attribute barcode is required.":
              row.message = this.$t("barcodeRequired");
              break;
            case "Attribute category is required.":
              row.message = this.$t("categoryRequired");
              break;
            case "Attribute name is required.":
              row.message = this.$t("nameRequired");
              break;
            case "Attribute price is required.":
              row.message = this.$t("priceRequired");
              break;
            case "Attribute price must be a number.":
              row.message = this.$t("priceMustBeNumber");
              break;
            case "Attribute cost must be a number.":
              row.message = this.$t("costRequired");
              break;
            case "Attribute ratio must be a number.":
              row.message = this.$t("ratioRequired");
              break;
            case "Barcode duplicated":
              row.message = this.$t("barcodedup");
              break;
            case "Attribute cost should not contain alphabet.":
              row.message = this.$t("costNotAlphabet");
              break;
            case "Attribute price should not contain alphabet.":
              row.message = this.$t("priceNotAlphabet");
              break;
            case "Attribute sku is invalid.":
              row.message = this.$t("skuInvalid");
              break;
            case "Attribute barcode is invalid.":
              row.message = this.$t("barcodeInvalid");
              break;
            case "Attribute category is invalid.":
              row.message = this.$t("categoryInvalid");
              break;
            case "Attribute name is invalid.":
              row.message = this.$t("nameInvalid");
              break;
            case "Attribute price is invalid.":
              row.message = this.$t("priceInvalid");
              break;
            case "Barcode already exists":
              row.message = this.$t("barcodeExists");
              break;
            case "Barcode already exists.":
              row.message = this.$t("barcodeExists");
              break;
            case "จำเป็นต้องมีข้อมูล SKU Code":
              row.message = this.$t("skuRequired");
              break;
            case "จำเป็นต้องมีข้อมูลบาร์โค้ด":
              row.message = this.$t("barcodeRequired");
              break;
            case "จำเป็นต้องมีข้อมูลกลุ่มสินค้า":
              row.message = this.$t("categoryRequired");
              break;
            case "จำเป็นต้องมีข้อมูลชื่อสินค้า":
              row.message = this.$t("nameRequired");
              break;
            case "จำเป็นต้องมีข้อมูลราคาสินค้า":
              row.message = this.$t("priceRequired");
              break;
            case "ราคาสินค้าต้องเป็นตัวเลข":
              row.message = this.$t("priceMustBeNumber");
              break;
            case "ราคาต้นทุนต้องเป็นตัวเลข":
              row.message = this.$t("costRequired");
              break;
            case "จำนวนตัดสต็อกต้องเป็นตัวเลข":
              row.message = this.$t("ratioRequired");
              break;
            case "บาร์โค้ดซ้ำกัน":
              row.message = this.$t("barcodedup");
              break;
            case "ข้อมูลราคาต้องไม่มีตัวอักษร":
              row.message = this.$t("costNotAlphabet");
              break;
            case "ข้อมูลราคาต้องไม่มีตัวอักษร":
              row.message = this.$t("priceNotAlphabet");
              break;
            case "ข้อมูล SKU Code ไม่ถูกต้อง":
              row.message = this.$t("skuInvalid");
              break;
            case "ข้อมูลบาร์โค้ดไม่ถูกต้อง":
              row.message = this.$t("barcodeInvalid");
              break;
            case "ข้อมูลกลุ่มสินค้าไม่ถูกต้อง":
              row.message = this.$t("categoryInvalid");
              break;
            case "ข้อมูลชื่อสินค้าไม่ถูกต้อง":
              row.message = this.$t("nameInvalid");
              break;
            case "ข้อมูลราคาสินค้าไม่ถูกต้อง":
              row.message = this.$t("priceInvalid");
              break;
            case "มีสินค้าบาร์โค้ดนี้แล้ว":
              row.message = this.$t("barcodeExists");
              break;
            default:
              row.message = this.$t("serialNumberisDup");
              break;
          }
        }
      });
    },
  },
  watch: {
    "$i18n.locale": function (newLocale, oldLocale) {
      if (this.gridApi) {
        this.refreshTable();
        this.updateMessages();
      }
    },
    shopObjectId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.clearTable();
      }
    },
  },
};
</script>

<style>
@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-balham.css";

.green-cell {
  color: #29b289;
}

.pass-row {
  color: #4b5f71;
  /* สีเขียวอ่อน */
}

.red-row {
  color: #ee3e37;
  /* สีเขียวอ่อน */
}

.ag-theme-balham {
  --ag-background-color: #ffffff;
  --ag-foreground-color: #4b5f71;
  --ag-text-color: var(--ag-foreground-color);
  --ag-accent-color: #ffffff;
  --ag-invalid-color: #e02525;
  --ag-border-color: #e5e5e5;
  --ag-wrapper-border: solid 1px var(--ag-border-color);
  --ag-row-border: 10px solid black;
  --ag-header-border: var(--ag-row-border);
  --ag-footer-border: var(--ag-row-border);
  --ag-column-border: solid 1px var(--ag-border-color);
  --ag-column-header-border: var(--ag-column-border);
  --ag-column-header-border-height: 100%;
  --ag-pinned-column-border: solid 1px var(--ag-border-color);
  --ag-pinned-row-border: solid 1px var(--ag-border-color);
  --ag-side-panel-border: solid 1px var(--ag-border-color);
  --ag-side-button-selected-border: solid 1px var(--ag-border-color);
  --ag-side-button-selected-background-color: var(--ag-background-color);
  --ag-side-bar-background-color: var(--ag-chrome-background-color);
  --ag-font-family: sukhumvit;
  --ag-chrome-background-color: color-mix(in srgb,
      transparent,
      var(--ag-foreground-color) 2%);
  --ag-header-background-color: #f6f6f6;
  --ag-header-font-family: Inter;
  --ag-header-font-weight: 700;
  --ag-header-font-size: 16px;
  --ag-header-text-color: #4b5f71;
  --ag-header-cell-hover-background-color: transparent;
  --ag-header-cell-hover-background-transition-duration: 0.2s;
  --ag-data-color: #4b5f71;
  --ag-subtle-text-color: color-mix(in srgb, transparent, var(--ag-text-color) 50%);
  --ag-range-selection-border-style: solid;
  --ag-range-selection-border-color: var(--ag-accent-color);
  --ag-range-selection-background-color: color-mix(in srgb,
      transparent,
      var(--ag-accent-color) 20%);
  --ag-range-selection-chart-background-color: #0058ff1a;
  --ag-range-selection-chart-category-background-color: #00ff841a;
  --ag-range-selection-highlight-color: color-mix(in srgb,
      transparent,
      var(--ag-accent-color) 50%);
  --ag-row-hover-color: color-mix(in srgb, transparent, var(--ag-accent-color) 12%);
  --ag-column-hover-color: color-mix(in srgb, transparent, var(--ag-accent-color) 5%);
  --ag-selected-row-background-color: color-mix(in srgb,
      transparent,
      var(--ag-accent-color) 8%);
  --ag-modal-overlay-background-color: color-mix(in srgb,
      transparent,
      var(--ag-background-color) 66%);
  --ag-odd-row-background-color: #ffffff;
  --ag-border-radius: 0px;
  --ag-wrapper-border-radius: 0px;
  --ag-cell-horizontal-padding: calc(var(--ag-grid-size) * 2 * var(--ag-cell-horizontal-padding-scale));
  --ag-cell-widget-spacing: calc(var(--ag-grid-size) * 1.5);
  --ag-cell-horizontal-padding-scale: 0.8;
  --ag-label-widget-spacing: var(--ag-grid-size);
  --ag-row-group-indent-size: calc(var(--ag-cell-widget-spacing) + var(--ag-icon-size));
  --ag-value-change-delta-up-color: #43a047;
  --ag-value-change-delta-down-color: #e53935;
  --ag-value-change-value-highlight-background-color: #16a08580;
  --ag-grid-size: 4px;
  --ag-font-size: 16px;
  --ag-row-height: calc(max(var(--ag-icon-size), var(--ag-font-size)) + var(--ag-grid-size) * 3.5 * var(--ag-row-vertical-padding-scale));
  --ag-row-vertical-padding-scale: 1.5;
  --ag-header-height: calc(max(var(--ag-icon-size), var(--ag-font-size)) + var(--ag-grid-size) * 4.25 * var(--ag-header-vertical-padding-scale));
  --ag-header-vertical-padding-scale: 1.5;
  --ag-popup-shadow: 0 0 16px 0 #00000026;
  --ag-dropdown-shadow: 0 1px 4px 1px #babfc766;
  --ag-drag-ghost-background-color: var(--ag-background-color);
  --ag-drag-ghost-border: solid 1px var(--ag-border-color);
  --ag-drag-ghost-shadow: var(--ag-popup-shadow);
  --ag-focus-shadow: 0 0 0 3px color-mix(in srgb, transparent, var(--ag-accent-color) 50%);
  --ag-side-bar-panel-width: 250px;
  --ag-header-column-resize-handle-display: block;
  --ag-header-column-resize-handle-height: 30%;
  --ag-header-column-resize-handle-width: 2px;
  --ag-header-column-resize-handle-color: var(--ag-border-color);
  --ag-widget-container-horizontal-padding: calc(var(--ag-grid-size) * 1.5);
  --ag-widget-container-vertical-padding: calc(var(--ag-grid-size) * 1.5);
  --ag-widget-horizontal-spacing: calc(var(--ag-grid-size) * 1.5);
  --ag-widget-vertical-spacing: var(--ag-grid-size);
  --ag-list-item-height: calc(var(--ag-icon-size) + var(--ag-widget-vertical-spacing));
  --ag-icon-size: 16px;
  --ag-toggle-button-width: 28px;
  --ag-toggle-button-height: 18px;
  --ag-toggle-button-border-width: 2px;
  --ag-toggle-button-on-border-color: var(--ag-accent-color);
  --ag-toggle-button-on-background-color: var(--ag-accent-color);
  --ag-toggle-button-off-border-color: color-mix(in srgb,
      var(--ag-background-color),
      var(--ag-foreground-color) 30%);
  --ag-toggle-button-off-background-color: color-mix(in srgb,
      var(--ag-background-color),
      var(--ag-foreground-color) 30%);
  --ag-toggle-button-switch-border-color: var(--ag-toggle-button-off-border-color);
  --ag-toggle-button-switch-background-color: var(--ag-background-color);
  --ag-checkbox-border-width: 1px;
  --ag-checkbox-border-radius: var(--ag-border-radius);
  --ag-checkbox-unchecked-background-color: var(--ag-background-color);
  --ag-checkbox-unchecked-border-color: color-mix(in srgb,
      var(--ag-background-color),
      var(--ag-foreground-color) 30%);
  --ag-checkbox-checked-background-color: var(--ag-accent-color);
  --ag-checkbox-checked-border-color: var(--ag-accent-color);
  --ag-checkbox-checked-shape-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2210%22%20height%3D%227%22%20fill%3D%22none%22%3E%3Cpath%20stroke%3D%22%23000%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%221.75%22%20d%3D%22M1%203.5%203.5%206l5-5%22%2F%3E%3C%2Fsvg%3E");
  --ag-checkbox-checked-shape-color: var(--ag-background-color);
  --ag-checkbox-indeterminate-background-color: color-mix(in srgb,
      var(--ag-background-color),
      var(--ag-foreground-color) 30%);
  --ag-checkbox-indeterminate-border-color: color-mix(in srgb,
      var(--ag-background-color),
      var(--ag-foreground-color) 30%);
  --ag-checkbox-indeterminate-shape-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2210%22%20height%3D%222%22%20fill%3D%22none%22%3E%3Crect%20width%3D%2210%22%20height%3D%222%22%20fill%3D%22%23000%22%20rx%3D%221%22%2F%3E%3C%2Fsvg%3E");
  --ag-checkbox-indeterminate-shape-color: var(--ag-background-color);
  --ag-radio-checked-shape-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%226%22%20height%3D%226%22%20fill%3D%22none%22%3E%3Ccircle%20cx%3D%223%22%20cy%3D%223%22%20r%3D%223%22%20fill%3D%22%23000%22%2F%3E%3C%2Fsvg%3E");
  --ag-menu-border: solid 1px color-mix(in srgb, transparent, var(--ag-foreground-color) 20%);
  --ag-menu-background-color: color-mix(in srgb,
      var(--ag-background-color),
      var(--ag-foreground-color) 3%);
  --ag-menu-text-color: color-mix(in srgb,
      var(--ag-background-color),
      var(--ag-foreground-color) 95%);
  --ag-menu-shadow: var(--ag-popup-shadow);
  --ag-menu-separator-color: var(--ag-border-color);
  --ag-set-filter-indent-size: var(--ag-icon-size);
  --ag-chart-menu-button-border: solid 1px var(--ag-border-color);
  --ag-icon-button-hover-color: color-mix(in srgb,
      transparent,
      var(--ag-foreground-color) 10%);
  --ag-dialog-shadow: var(--ag-popup-shadow);
  --ag-dialog-border: solid 1px color-mix(in srgb, transparent, var(--ag-foreground-color) 20%);
  --ag-panel-background-color: var(--ag-background-color);
  --ag-panel-title-bar-background-color: var(--ag-header-background-color);
  --ag-panel-title-bar-border: solid 1px var(--ag-border-color);
  --ag-column-select-indent-size: var(--ag-icon-size);
  --ag-tool-panel-separator-border: solid 1px var(--ag-border-color);
  --ag-tooltip-background-color: var(--ag-chrome-background-color);
  --ag-tooltip-text-color: var(--ag-text-color);
  --ag-tooltip-border: solid 1px var(--ag-border-color);
  --ag-column-drop-cell-background-color: color-mix(in srgb,
      transparent,
      var(--ag-foreground-color) 7%);
  --ag-column-drop-cell-border: solid 1px color-mix(in srgb, transparent, var(--ag-foreground-color) 13%);
  --ag-advanced-filter-builder-button-bar-border: solid 1px var(--ag-border-color);
  --ag-advanced-filter-builder-indent-size: calc(var(--ag-grid-size) * 2 + var(--ag-icon-size));
  --ag-advanced-filter-builder-join-pill-color: #f08e8d;
  --ag-advanced-filter-builder-column-pill-color: #a6e194;
  --ag-advanced-filter-builder-option-pill-color: #f3c08b;
  --ag-advanced-filter-builder-value-pill-color: #85c0e4;
  --ag-filter-tool-panel-group-indent: calc(var(--ag-grid-size));
  --ag-icon-button-hover-background-color: color-mix(in srgb,
      transparent,
      var(--ag-foreground-color) 10%);
  --ag-row-loading-skeleton-effect-color: rgba(66, 66, 66, 0.2);
  --ag-tab-bar-background-color: color-mix(in srgb,
      transparent,
      var(--ag-foreground-color) 5%);
  --ag-tab-bar-horizontal-padding: 0;
  --ag-tab-bar-top-padding: 0;
  --ag-tab-background-color: transparent;
  --ag-tab-text-color: color-mix(in srgb, transparent, var(--ag-text-color) 70%);
  --ag-tab-horizontal-padding: calc(var(--ag-grid-size));
  --ag-tab-top-padding: calc(var(--ag-grid-size));
  --ag-tab-bottom-padding: calc(var(--ag-grid-size));
  --ag-tab-spacing: 0;
  --ag-tab-hover-background-color: var(--ag-tab-background-color);
  --ag-tab-hover-text-color: var(--ag-text-color);
  --ag-tab-selected-background-color: var(--ag-background-color);
  --ag-tab-selected-text-color: var(--ag-text-color);
  --ag-tab-selected-border-width: 1px;
  --ag-tab-selected-border-color: var(--ag-border-color);
  --ag-tab-selected-underline-color: transparent;
  --ag-tab-selected-underline-width: 0;
  --ag-tab-selected-underline-transition-duration: 0;
  --ag-tab-bar-border: solid 1px var(--ag-border-color);
  --ag-input-background-color: var(--ag-background-color);
  --ag-input-border: solid 1px var(--ag-border-color);
  --ag-input-border-radius: var(--ag-border-radius);
  --ag-input-text-color: var(--ag-text-color);
  --ag-input-padding-start: var(--ag-grid-size);
  --ag-input-height: calc(max(var(--ag-icon-size), var(--ag-font-size)) + var(--ag-grid-size) * 2);
  --ag-input-focus-background-color: var(--ag-input-background-color);
  --ag-input-focus-border: solid 1px var(--ag-accent-color);
  --ag-input-focus-shadow: var(--ag-focus-shadow);
  --ag-input-focus-text-color: var(--ag-input-text-color);
  --ag-input-disabled-background-color: color-mix(in srgb,
      var(--ag-background-color),
      var(--ag-foreground-color) 6%);
  --ag-input-disabled-border: var(--ag-input-border);
  --ag-input-disabled-text-color: color-mix(in srgb,
      transparent,
      var(--ag-text-color) 50%);
  --ag-input-invalid-background-color: var(--ag-input-background-color);
  --ag-input-invalid-border: solid 1px var(--ag-invalid-color);
  --ag-input-invalid-text-color: var(--ag-input-text-color);
}

.ag-cell,
.ag-full-width-row .ag-cell-wrapper.ag-row-group {
  border-top: 0px;
  border-left: 1px solid #f6f6f6;
  border-bottom: 1px solid #f6f6f6;
}

.ag-header-cell {
  border-top: 1px solid #e5e5e5;
  border-left: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  color: #4b5f71;
}

.ag-header-cell-resize {
  display: none;
}

.ag-header-cell::before,
.ag-header-group-cell:not(.ag-header-span-height.ag-header-group-cell-no-group)::before {
  display: none;
}
</style>